var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"fixed-header":"","height":"calc(100vh - 200px)","hideDefaultHeader":"","headers":_vm.headers,"items":_vm.listItems,"loading":_vm.loading,"footer-props":_vm.footer,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0},on:{"update:options":function($event){_vm.options=$event},"update:page":function (page) { return _vm.onPagination({ page: page }); },"update:items-per-page":function (size) { return _vm.onPagination({ size: size }); }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.headers),function(ref){
var text = ref.text;
var value = ref.value;
var colspan = ref.colspan;
return _c('th',{key:text,class:_vm.headerClassControl(value),attrs:{"colspan":colspan}},[_vm._v(_vm._s(text))])}),0),_c('FilterRow',{attrs:{"items":_vm.headers},on:{"input":_vm.onPagination}})],1)]},proxy:true},{key:"item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('tr',_vm._l((headers),function(ref){
var value = ref.value;
return _c('td',{key:value},[(~value.indexOf('question_'))?[_c('CompactCeil',{attrs:{"text":_vm.getQuestionData(item ,value)}})]:[_vm._v(" "+_vm._s(item[value])+" ")]],2)}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }